import { combineReducers } from 'redux';
import * as videokubeUtils from 'videokube-front-library/dist/videokubeUtils';
import { appRecucer } from 'videokube-front-library/dist/store';


export const defaultState = videokubeUtils.getDefaultState();

type State = typeof defaultState;

const appReducer = appRecucer;

const finalReducer = combineReducers({
  app: appReducer,
});

export type AppState = { app: State };
export { finalReducer };
